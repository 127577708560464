import React, { Component, useState, useEffect } from 'react';
import './NavMenu.css';
import gridImageOne from './assets/Academics.png'
import gridImageTwo from './assets/banner/dance.jpg'
import gridImageThree from './assets/banner/speech.jpg'
import $ from 'jquery';

export class Home extends Component {



    shoot(event, someParameter, activeClass) {
        $('.card-group__item').removeClass('card-group__item--top');

        $('.card-group__item' + someParameter).addClass('card-group__item--top');
    }

    componentDidMount() {

        //      $(".btn1").click(function(){
        //          $(".page1").addClass('showItems');
        //});



        $(".card-group__title").show('slow');
        $(".page1").show('slow');
        $(".btn1").click(function () {
            
            $(".page1").show('slow');
            $(".page2").hide('slow');
            $(".page3").hide('slow');
            $(".page4").hide('slow');
            $(".page5").hide('slow');
            $(".page5").hide('slow');
            $('.page1').addClass('card - group__button--nav--active');
            $('.page2').removeClass('card - group__button--nav--active');
            $('.page3').removeClass('card - group__button--nav--active');
            $('.page4').removeClass('card - group__button--nav--active');

        });
        $(".btn2").click(function () {
            $(".page1").hide('slow');
            $(".page2").show('slow');
            $(".page3").hide('slow');
            $(".page4").hide('slow');
            $(".page5").hide('slow');
            $('.page1').removeClass('card - group__button--nav--active');
            $('.page2').addClass('card - group__button--nav--active');
            $('.page3').removeClass('card - group__button--nav--active');
            $('.page4').removeClass('card - group__button--nav--active');

        });
        $(".btn3").click(function () {
            $(".page1").hide('slow');
            $(".page2").hide('slow');
            $(".page3").show('slow');
            $(".page4").hide('slow');
            $(".page5").hide('slow');
            $('.page1').removeClass('card - group__button--nav--active');
            $('.page2').removeClass('card - group__button--nav--active');
            $('.page3').addClass('card - group__button--nav--active');
            $('.page4').removeClass('card - group__button--nav--active');
        });
        $(".btn4").click(function () {
            $(".page1").hide('slow');
            $(".page2").hide('slow');
            $(".page3").hide('slow');
            $(".page4").show('slow');
            $(".page5").hide('slow');

        });
        $(".btn5").click(function () {
            $(".page1").hide('slow');
            $(".page2").hide('slow');
            $(".page3").hide('slow');
            $(".page4").hide('slow');
            $(".page5").show('slow');
        });


        $(".accordion button").on("click", function () {
            const itemToggle = $(this).attr("aria-expanded");

            $(".accordion button").attr("aria-expanded", "false");

            if (itemToggle == "false") {
                $(this).attr("aria-expanded", "true");
            }
        });


        $(window).scroll(function () {
            if ($(this).scrollTop() > 300) {
                $('#back-to-top').fadeIn();
            } else {
                $('#back-to-top').fadeOut();
            }
        });


        $('#back-to-top').click(function () {
            $('html, body').animate({ scrollTop: 0 }, '300');

        });
        $('#headerid').removeClass('header--menu');
        $('.header__menu-toggle').click(function () {
            $('#headerid').toggleClass('header--menu');
        });

    }





    render() {

        return (

            <div>
                {/*<div id="myModal" class="modal fade" role="dialog">*/}
                {/*    <div class="modal-dialog">*/}

                {/*        <div class="modal-content">*/}
                {/*            <div class="modal-header">*/}
                {/*                <button type="button" class="close" data-dismiss="modal">&times;</button>*/}

                {/*            </div>*/}
                {/*            <div class="modal-body text-center">*/}
                {/*                <h1>Full screen Transparent Bootstrap Modal</h1>*/}
                {/*                <p>FEEL FRREE TO GET YOUR MODAL CODE HERE FOLKS.</p>*/}
                {/*                <a class="pre-order-btn" href="#">GET THE MODAL CODE</a>*/}
                {/*            </div>*/}
                {/*            <div class="modal-footer">*/}

                {/*            </div>*/}
                {/*        </div>*/}

                {/*    </div>*/}
                {/*</div>*/}

                <div className="home page-template page-template-template-home page-template-template-home-php page page-id-30924 body--header-overlay tribe-no-js">

                    <div id="headerid" className="header ally-focus-within header--menu">
                        <div className="header__wrap">
                            <div className=" header__container pb-4 px-lg-5">
                                <div className="d-flex">
                                    <a href="/" className="malareddy-logo d-none d-lg-block">
                                        <img width="400" src="/assets/malla-reddy-college-logo.png" alt="logo image" loading="lazy" />
                                    </a>
                                    <a href="#" className="malareddy-logo d-block d-lg-none">
                                        <img className="img-fluid" src="/assets/malla-reddy-college-logo-mobile.png" width='368' alt="logo image" loading="lazy" />
                                    </a>

                                </div>

                                <div className="header__search">
                                    <form id="search-form" className="header__form" method="get"
                                        action="#">
                                        <label className="header__label">
                                            <span className="hide">Search</span>
                                            <input type="search" className="header__input" placeholder="Type here to search"
                                                id="cludoquery" name="cludoquery" />
                                        </label>
                                        <button type="submit" className="header__submit">Go</button>
                                    </form>

                                </div>
                                <button className="header__menu-toggle"><span className="hide">Menu</span></button>
                                <div className="header__menu">
                                    <div className="header__menu__wrap">
                                        <img className="img-fluid pb-lg-5" src="/assets/logo/new-top-icons.png" alt="icon" loading="lazy" />
                                        <nav className="header__primary">
                                            <ul id="menu-navigation" className="header__primary__list">
                                                <li id="menu-item-639"
                                                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-639">
                                                    <a href="/">Home</a>
                                                </li>
                                                <li id="menu-item-639"
                                                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-639 ">
                                                    <a href="/Home/About">About College</a>
                                                </li>
                                                <li id="menu-item-640"
                                                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-640">
                                                    <a href="/Home/InfrastructureFacilities">Infrastructure</a>
                                                </li>
                                                <li id="menu-item-640"
                                                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-640">
                                                    <a href="/Home/Admissions">Admissions</a>
                                                </li>
                                                <li id="menu-item-640"
                                                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-640">
                                                    <a href="/Home/Departments">Departments</a>
                                                </li>
                                                <li id="menu-item-640"
                                                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-640">
                                                    <a href="/Home/Placements">Placements</a>
                                                </li>
                                                <li id="menu-item-640"
                                                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-640">
                                                    <a href="/Home/Faculty">Faculty</a>
                                                </li>
                                                <li id="menu-item-640"
                                                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-640">
                                                    <a href="/Home/Courses">Courses</a>
                                                </li>
                                                <li id="menu-item-640"
                                                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-640">
                                                    <a href="/Home/Events">Events</a>
                                                </li>

                                                <li id="menu-item-640"
                                                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-640">
                                                    <a href="/Home/Careers">Careers</a>
                                                </li>
                                                <li id="menu-item-640"
                                                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-640">
                                                    <a href="/Home/Alumni">Alumni</a>
                                                </li>
                                                <li id="menu-item-640"
                                                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-640">
                                                    <a href="/Home/Contact">Contact</a>
                                                </li>
                                                <li id="menu-item-640"
                                                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-640 ">
                                                    <a href="https://mrecwexamcell.com/BEESERP/Login.aspx" target="_blank">
                                                        <b>
                                                            <i className="fa-solid fa-right-to-bracket loginIon"></i>
                                                            Login
                                                        </b>
                                                    </a>
                                                </li>
                                            </ul>

                                        </nav>
                                    </div>
                                </div>

                            </div>
                            <div className="col-12 d-flex align-items-cente marqueeeLine">
                                <p className="news-heading"><i class="fa fa-bullhorn small text-white fa-shake"></i>&nbsp;UPDATES </p>
                                <marquee
                                    className="col-md-11 y p-1" behavior="scroll" direction="left" scrollamount="5"
                                    onmouseover="this.stop();" onmouseout="this.start();">
                                    <a href="#" className="text-white">
                                        Proud Moments for MRECW !!
                                        Hearty Congratulations - 387 Students placed in ACCENTURE from 2019 - 23 Batch.
                                    </a>
                                    <a href="#" className="text-white">
                                        368 Students placed in DXC TECHNOLOGY from 2019 - 23 Batch.
                                    </a>
                                    <a href="#" className="text-white">
                                        339 Students placed in HCL TECHNOLOGY from 2019 - 23 Batch.
                                    </a>
                                    <a href="#" className="text-white">
                                        198 Students placed in COGNIZANT from 2019 - 23 Batch.
                                    </a>
                                    <a href="#" className="text-white">
                                        2 Students placed in Lowes India with highest package of 19.5 LPA from 2019 - 23 Batch.
                                        vement ofMRECW !! NIRF Indian Ranking - 2021, Accepted by MHRD, Govt. of India:: -vement of
                                        MRECW !! NIRF Indian Ranking - 2021, Accepted by MHRD, Govt. of India:: -
                                    </a>
                                    <a href="#" className="text-white">
                                        ::Congatulations toMRECW for more than 65,000 online student
                                        certifications completion during COVID-19 period till date, in trending technologies through
                                        Coursera like AI &amp; ML, IOT, Cloud Computing, Cyber Security, Data Science, etc, CISCO C,
                                        CISCO Python, CISCO Linux, Pearson English certification, TCS ION certifications and IBM
                                        Cognitive courses (AI &amp; ML, Data Science &amp; Python) and Amazon wow.::
                                    </a>
                                    <a href="#" className="text-white">
                                        ::Congatulations to 1st year B.Tech students for completing more
                                        than 20,000 online certifications during COVID-19 period till date. :: -
                                    </a>
                                    <a href="#" className="text-white">
                                        ::Congatulations to CSE students for completing more than 18,000
                                        online certifications during COVID-19 period till date:: -
                                    </a>
                                    <a href="#" className="text-white">
                                        ::Congatulations to ECE students for completing more than 17,000
                                        online certifications during COVID-19 period till date:: -
                                    </a>
                                    <a href="#" className="text-white">
                                        :: Congatulations to ECE students for completing more than 17,000
                                        online certifications during COVID-19 period till date.:: -
                                    </a>
                                    <a href="#" className="text-white">
                                        :: Congatulations to IT students for completing more than 4,500
                                        online certifications during COVID-19 period till date.:: -
                                    </a>
                                    <a href="#" className="text-white">
                                        :: Congatulations to EEE students for completing more than 5,500
                                        online certifications during COVID-19 period till date.:: -
                                    </a>
                                </marquee>
                            </div>
                        </div>
                    </div>
                    <div id="scrollto" className="scrollto"></div>
                    <div className="omnilert">
                        <div className="omnilert__container container">
                            <script type="text/javascript"
                                src="http://widgets.omnilert.net/5fb267efe64a62be1d534df4ecebcee8-621"></script>
                            <button className="omnilert__close">Close</button>
                        </div>
                    </div>
                </div>
                <div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="5" aria-label="Slide 6"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="6" aria-label="Slide 7"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="7" aria-label="Slide 8"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="8" aria-label="Slide 9"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="9" aria-label="Slide 10"></button>
                    </div>
                    <div className="carousel-inner">
 
                        <div className="carousel-item active" data-bs-interval="3000">
                            <img src="./assets/banner/block-1.png" className="d-none d-md-block w-100 " alt="..." />

                            <img src="./assets/mobile-banner/mobile-banner-1.png" className="d-block d-md-none w-100" alt="..." />
                        </div>
                        <div className="carousel-item" data-bs-interval="3000" >
                            <img src="./assets/banner/block-2.png" className="d-none d-md-block w-100" alt="..." />
                            <img src="./assets/mobile-banner/mobile-banner-2.png" className="d-block d-md-none w-100" alt="..." />
                        </div>
                        <div className="carousel-item" data-bs-interval="3000">
                            <img src="./assets/banner/block-3.png" className="d-none d-md-block w-100" alt="..." />
                            <img src="./assets/mobile-banner/mobile-banner-3.png" className="d-block d-md-none w-100" alt="..." />
                        </div>
                        <div className="carousel-item" data-bs-interval="3000">
                            <img src="./assets/banner/block-4.png" className="d-none d-md-block w-100" alt="..." />
                            <img src="./assets/mobile-banner/mobile-banner-4.png" className="d-block d-md-none w-100" alt="..." />
                        </div>
                        <div className="carousel-item" data-bs-interval="3000">
                            <img src="./assets/banner/block-5.png" className="d-none d-md-block d-md-block w-100" alt="..." />
                            <img src="./assets/mobile-banner/mobile-banner-5.png" className="d-block d-md-none w-100" alt="..." />
                        </div>
                        <div className="carousel-item" data-bs-interval="3000">
                            <img src="./assets/banner/block-6.png" className="d-none d-md-block w-100" alt="..." />
                            <img src="./assets/mobile-banner/mobile-banner-6.png" className="d-block d-md-none w-100" alt="..." />
                        </div>
                        <div className="carousel-item" data-bs-interval="3000">
                            <img src="./assets/banner/madhavee-latha-principal.png" className="d-none d-md-block w-100" alt="..." />
                            <img src="./assets/mobile-banner/mobile-banner-10.png" className="d-block d-md-none w-100" alt="..." />
                        </div>

                        <div className="carousel-item" data-bs-interval="3000">
                            <img src="./assets/banner/gold-medal.jpg" className="d-none d-md-block w-100" alt="..." />
                            <img src="./assets/mobile-banner/mobile-banner-7.png" className="d-block d-md-none w-100" alt="..." />
                        </div>
                        <div className="carousel-item" data-bs-interval="3000">
                            <img src="./assets/banner/play-ground.jpg" className="d-none d-md-block w-100" alt="..." />
                            <img src="./assets/mobile-banner/mobile-banner-8.png" className="d-block d-md-none w-100" alt="..." />
                        </div>
                        <div className="carousel-item" data-bs-interval="3000">
                            <img src="./assets/banner/banner_01.png" className="d-none d-md-block w-100" alt="..." />
                            <img src="./assets/mobile-banner/mobile-banner-9.png" className="d-block d-md-none w-100" alt="..." />
                        </div>
                    </div>
                    
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>


                <div className="row card-group">
                    <div className="col-lg-3 order-2 order-lg-1">
                        <div className="sideBtnBar mx-4 mx-lg-0 ms-lg-5 pt-0">
                            <aside className="Homesidebar">
                                <nav className="sideNav">
                                    <ul>
                                        <li>
                                            <a href="/Home/Management"><i className="fa-solid fa-caret-right"></i> Management</a>
                                        </li>

                                        <li>
                                            <a href="/Home/Principal"><i className="fa-solid fa-caret-right"></i> Principal</a>
                                        </li>


                                        {/*<li>*/}
                                        {/*    <a href="/Home/Dean"><i className="fa-solid fa-caret-right"></i> Dean Academics </a>*/}
                                        {/*</li>*/}
                                        <li>
                                            <a href="/Home/Directors"><i className="fa-solid fa-caret-right"></i> Deans </a>
                                        </li>
                                        <li>
                                            <a href="https://ik.imagekit.io/ckjdgz4vmo/mainPage/GoverningBody.pdf?updatedAt=1681278972671" target="_blank"> <i className="fa-solid fa-caret-right"></i> Governing Body</a>
                                        </li>
                                        <li>
                                            <a href="/Home/Regulations"><i className="fa-solid fa-caret-right"></i> Academics </a>
                                        </li>
                                        <li>
                                            <a href="/Home/Exambranchautonomous"><i className="fa-solid fa-caret-right"></i> Examination Branch</a>
                                        </li>
                                        <li>
                                            <a href="/Home/MRECW_Cells"><i class="fa-solid fa-caret-right"></i> MRECW Cells</a>
                                        </li>
                                        <li>
                                            <a href="/Home/MRECW_Committees"><i className="fa-solid fa-caret-right"></i> MRECW Committes</a>
                                        </li>


                                        {/*<li>*/}
                                        {/*    <a href="/Home/WomenProtecitionCell"><i className="fa-solid fa-caret-right"></i> Women Protection Cell</a>*/}
                                        {/*</li>*/}
                                        {/*<li>*/}
                                        {/*    <a href="/Home/Grievance"><i className="fa-solid fa-caret-right"></i> Grievances Redressal Cell</a>*/}
                                        {/*</li>*/}
                                        {/*<li>*/}
                                        {/*    <a href="/assets/documents/ombudsperson.pdf" target="_blank"><i className="fa-solid fa-caret-right"></i> Ombudsperson Details</a>*/}
                                        {/*</li>*/}

                                        {/*<li>*/}
                                        {/*    <a href="/assets/images/onlinecertifications.jpg" target="_blank"><i className="fa-solid fa-caret-right"></i> Online Certifications</a>*/}
                                        {/*</li>*/}
                                        {/*<li>*/}
                                        {/*    <a href="/Home/Tech_Clubs"><i className="fa-solid fa-caret-right"></i> MRECW Technical Clubs</a>*/}
                                        {/*</li>*/}
                                        <li>
                                            <a href="/Home/StudentsChapters"><i className="fa-solid fa-caret-right"></i> Student Chapters</a>
                                        </li>
                                        {/*<li>*/}
                                        {/*    <a href="/Home/IEEE_SB"><i className="fa-solid fa-caret-right"></i> IEEE SB</a>*/}
                                        {/*</li>*/}
                                        <li>
                                            <a href="/assets/documents/NAAC-SSR-REPORT.pdf" target="_blank"><i className="fa-solid fa-caret-right"></i> NAAC SSR Report</a>
                                        </li>
                                        <li>
                                            <a href="/Home/IQAC"><i className="fa-solid fa-caret-right"></i> IQAC</a>
                                        </li>
                                        {/*<li>*/}
                                        {/*    <a href="~/assets/documents/ANTIRAGGING2022.pdf" target="_blank"><i className="fa-solid fa-caret-right"></i> Anti - Ragging Committee</a>*/}
                                        {/*</li>*/}
                                        {/*<li>*/}
                                        {/*    <a href="/assets/documents/DISIPLINARY2022.pdf" target="_blank"><i className="fa-solid fa-caret-right"></i> Disciplinary Committee</a>*/}
                                        {/*</li>*/}
                                        {/*<li>*/}
                                        {/*    <a href="/assets/documents/SC_STCommittee2022.pdf" target="_blank"><i className="fa-solid fa-caret-right"></i> SC/ST Committee</a>*/}
                                        {/*</li>*/}

                                        <li>
                                            <a href="/Home/NSSunit"><i className="fa-solid fa-caret-right"></i> NSS Unit</a>
                                        </li>
                                        <li>
                                            <a href="/assets/documents/AcademicCouncil.pdf" target="_blank"><i className="fa-solid fa-caret-right"></i> Academic Council</a>
                                        </li>
                                        <li>
                                            <a href="/assets/documents/Mandatory_Disclosure_MRECW.pdf" target="_blank"><i className="fa-solid fa-caret-right"></i> Mandatory Disclosure</a>
                                        </li>
                                        <li>
                                            <a href="/Home/Feedback"><i className="fa-solid fa-caret-right"></i> Feedback</a>
                                        </li>
                                        <li>
                                            <a href="https://www.aicte-india.org/feedback/index.php" target="_blank"><i className="fa-solid fa-caret-right"></i> Feedback AICTE</a>
                                        </li>
                                        <li>
                                            <a href="/Home/EContent"><i className="fa-solid fa-caret-right"></i> E-Content</a>
                                        </li>
                                        <li>
                                            <a href="/Home/nirf"><i className="fa-solid fa-caret-right"></i> NIRF Ranking</a>
                                        </li>
                                        <li>
                                            <a href="/Home/Ariia"><i className="fa-solid fa-caret-right"></i> ARIIA Ranking</a>
                                        </li>
                                        <li>
                                            <a href="/Home/Nisp"><i className="fa-solid fa-caret-right"></i> MRECW NISP</a>
                                        </li>
                                        <li>
                                            <a href="/assets/documents/Code of Conduct-Students.pdf"><i className="fa-solid fa-caret-right"></i> Code of Conduct</a>
                                        </li>

                                        <li>
                                            <a href="/assets/documents/SERVICE RULES.pdf"><i className="fa-solid fa-caret-right"></i> Service Rules</a>
                                        </li>
                                        {/*<li>*/}
                                        {/*    <a href="/Home/RandD"><i className="fa-solid fa-caret-right"></i> Research &amp; Development Cell</a>*/}
                                        {/*</li>*/}
                                        {/*<li>*/}
                                        {/*    <a href="/Home/IIPC"><i className="fa-solid fa-caret-right"></i> Industry Institute Partnership Cell</a>*/}
                                        {/*</li>*/}
                                        {/*<li>*/}
                                        {/*    <a href="/Home/Edcell"><i className="fa-solid fa-caret-right"></i> Entreprenuer Development Cell</a>*/}
                                        {/*</li>*/}
                                        <li>
                                            <a href="/Home/Tbi"><i className="fa-solid fa-caret-right"></i> Technology Business Incubation Centre</a>
                                        </li>
                                        <li>
                                            <a href="/Home/Centres_of_Excellence"><i className="fa-solid fa-caret-right"></i> Centres of Excellence</a>
                                        </li>

                                        {/*<li>*/}
                                        {/*    <a href="/Home/Cdcskills"><i className="fa-solid fa-caret-right"></i> Centre for Development of Communication Skills</a>*/}
                                        {/*</li>*/}
                                        {/*<li>*/}
                                        {/*    <a href="/Home/Mentoring"><i className="fa-solid fa-caret-right"></i> Mentoring &amp; Counselling Cell</a>*/}
                                        {/*</li>*/}
                                        {/*<li>*/}
                                        {/*    <a href="/Home/VADDC"><i className="fa-solid fa-caret-right"></i> Value Added Certification Courses</a>*/}
                                        {/*</li>*/}
                                        {/*<li>*/}
                                        {/*    <a href="/Home/CareerGuidance"><i class="fa-solid fa-caret-right"></i> Career Guidance</a>*/}
                                        {/*</li>*/}
                                        {/*<li>*/}
                                        {/*    <a href="/assets/documents/Internal Complaints Committee 2022.pdf" target="_blank"><i className="fa-solid fa-caret-right"></i> Internal Complaints Committee</a>*/}
                                        {/*</li>*/}

                                        <a href="https://mallareddyecw.com/MRECWNEW/MRECWNEW.html" target='blanbk'><img className="img-fluid mx-auto " src="/assets/navbarImages/leftImages/left-1.png" alt="loginImgg" loading="lazy" /></a>
                                        <a href="https://mrecwexamcell.com/BEESERP/Login.aspx" target='blanbk'><img className="img-fluid pt-1 mx-auto" src="/assets/navbarImages/leftImages/left-2.png" alt="sideImg" loading="lazy" /></a>
                                        <a href="https://ssolive.myclassboard.com/Account/Login?ReturnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fclient_id%3DDIL4KLQ05IV1JZOXCLM0%26redirect_uri%3Dhttps%253A%252F%252Ficici.myclassboard.com%252Fsso%252FCallback%26response_type%3Dcode%26scope%3Dopenid%2520profile%2520offline_access" target='blanbk'>
                                            <img className="img-fluid pt-1 mx-auto" src="/assets/navbarImages/leftImages/left-3.png" alt="sideImg" loading="lazy" />
                                        </a>
                                        <a href="/Home/TermsConditions"><img className="img-fluid pt-1 mx-auto" src="/assets/navbarImages/leftImages/left-4.png" alt="sideImg" loading="lazy" /></a>
                                        <a href="/Home/Library"><img className="img-fluid pt-1 mx-auto" src="/assets/navbarImages/leftImages/left-10.png" alt="sideImg" loading="lazy" /></a>
                                        {/* <a href="/Home/Newsletter"><img className="img-fluid pt-2 mx-auto" src="/assets/navbarImages/leftImages/left-5.png" alt="sideImg" loading="lazy" /></a>*/}
                                        <a href="http://www.publishingindia.com/ijrsp" target='_blank'><img className="img-fluid pt-1 mx-auto" src="/assets/navbarImages/leftImages/left-8.png" alt="sideImg" loading="lazy" /></a>
                                        <a href="/Home/Media"><img className="img-fluid pt-1 mx-auto" src="/assets/navbarImages/leftImages/left-7.png" alt="sideImg" loading="lazy" /></a>
                                        <a href="../assets/images/media/new2Category B Application Form.pdf" target='_blank'><img className="img-fluid pt-1 mx-auto" src="/assets/navbarImages/leftImages/left-6.png" alt="sideImg" loading="lazy" /></a>

                                        {/*<a target='_blank' className="mt-1" href="https://www.youtube.com/watch?v=R-zEaQ_G204">*/}
                                        {/*    <img className="img-fluid mx-auto mt-1" src="./assets/navbarImages/social-media/youtbe.png" alt="blog" loading="lazy" />*/}
                                        {/*</a>*/}
                                        {/*<a target='_blank' className="mt-2" href="https://www.instagram.com/mrecwautonomous/">*/}
                                        {/*    <img className="img-fluid mx-auto mt-1" src="./assets/navbarImages/social-media/instagram.png" alt="blog" loading="lazy" />*/}
                                        {/*</a>*/}
                                        {/*<a target='_blank' className="mt-2" href="https://www.facebook.com/MRECWonline?ref=embed_page">*/}
                                        {/*    <img className="img-fluid mx-auto mt-1" src="./assets/navbarImages/social-media/facebook.png" alt="blog" loading="lazy" />*/}
                                        {/*</a>*/}

                                    </ul>
                                </nav>

                            </aside>
                        </div>
                    </div>
                    <div className="col-lg-6 order-1 order-lg-2">
                        <div className="image-grid py-3 mb-4 mb-lg-5">
                            <div className="container container--md image-grid__container fade-in fade-in--active">
                                <div className="image-grid__content mt-4">
                                    <h6>
                                        Welcome to Malla Reddy Engineering College for Women
                                    </h6>
                                    <p><a className="button button--inline" href="/Home/Admissions">Admissions Open</a></p>
                                </div>
                                <div className="image-grid__items">

                                    <div className="image-grid__item">
                                        <a className="image-grid__link" href="javascript:;" data-minimodal="image-grid-2" title="">
                                            <img width="400" height="300" src='/assets/admission-imgs/Welcome-1.png' className="" loading="lazy" sizes="(max-width: 400px) 100vw, 400px" alt="studentsLife" />

                                        </a>
                                    </div>
                                    <div className="image-grid__item">
                                        <a className="image-grid__link" href="javascript:;" data-minimodal="image-grid-2" title="">
                                            <img width="400" height="300" src='/assets/admission-imgs/Welcome-2.png' className="attachment-width-400 size-width-400" alt="studentsLife" loading="lazy" sizes="(max-width: 400px) 100vw, 400px" />

                                        </a>
                                    </div>
                                    <div className="image-grid__item">
                                        <a className="image-grid__link" href="javascript:;" data-minimodal="image-grid-2" title="">
                                            <img width="400" height="300" src='/assets/admission-imgs/Welcome-3.png' className="attachment-width-400 size-width-400" alt="studentsLife" loading="lazy" sizes="(max-width: 400px) 100vw, 400px" />

                                        </a>
                                    </div>

                                    <div className="image-grid__item">
                                        <a className="image-grid__link" href="javascript:;" data-minimodal="image-grid-2" title="">
                                            <img width="400" height="300" src='/assets/admission-imgs/Welcome-4.png' className="attachment-width-400 size-width-400" alt="studentsLife" loading="lazy" sizes="(max-width: 400px) 100vw, 400px" />

                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="news-events mission-section mb-4 mb-lg-5">
                            <div className="container container--md news-events__container fade-in fade-in--active">
                                <div className="news-events__group">
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <img src='/assets/vision.jpeg' className='vision-icon img-fluid d-block d-lg-none' height='100' />
                                        <h4 className=" news-events__heading videos-border">
                                          Vision
                                        </h4>                                   
                                     </div>
                                    <div className="news-events__list">
                                        <div className="news-events__item">
                                            <div className="news-events__text">
                                                <ul class="list">
                                                    <li>
                                                        Visualizing a great future for the intelligentsia by imparting state-of the art Technologies in the field of Engineering and Technology for the bright future and prosperity of the students.
                                                    </li>
                                                    <li>
                                                        To offer world class training to the promising Engineers.
                                                    </li>


                                                 </ul>
                                               
                                            </div>
                                        </div>
                                       
                                    </div>
                                   
                                </div>
                                <div className="news-events__group mt-3 mt-lg-0">
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <img src='/assets/mission.jpeg' className='vision-icon img-fluid d-block d-lg-none' height='100' />
                                        <h4 className="news-events__heading videos-border">  Mission</h4>
                                    </div>
                                   
                                    <div className="news-events__list">
                                        <div className="news-events__item">
                                            <div className="news-events__text">
                                                <ul class="list">
                                                    <li>
                                                        To nurture high level of Decency, Dignity and Discipline in women to attain high intellectual abilities.

                                                    </li>
                                                    <li>
                                                        To produce employable students at National and International levels by effective training programmes.
                                                    </li>
                                                    <li>
                                                        To create pleasant academic environment for generating high level learning attitudes
                                                    </li>

                                                </ul>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="px-3 px-lg-0 pb-4 pb-lg-5">
                            <div className="container--md card-group__container fade-in fade-in--active">
                                <div className="card-group__side">
                                    <h2 className="h6">
                                        ORGANIZING COMMITTEE
                                        <br />
                                        PATRONS  CHAIRS

                                    </h2>
                                    <div className="card-group__nav">
                                        <button className="h6 card-group__button card-group__button--nav  " onClick={(e) => {
                                            this.shoot(e, 1);
                                        }}><span className="card-group__button__text">Management</span></button>
                                        <button className="h6 card-group__button card-group__button--nav" onClick={(e) => {
                                            this.shoot(e, 2);
                                        }}><span className="card-group__button__text">Principal</span></button>
                                        <button className="h6 card-group__button card-group__button--nav" onClick={(e) => {
                                            this.shoot(e, 3);
                                        }}><span className="card-group__button__text"> Chairman</span></button>
                                        <button className="h6 card-group__button card-group__button--nav" onClick={(e) => {
                                            this.shoot(e, 4);
                                        }}><span className="card-group__button__text">Secretary</span></button>

                                        {/*<button className="h6 card-group__button card-group__button--nav" onClick={(e) => {*/}
                                        {/*    this.shoot(e, 5);*/}
                                        {/*}}><span className="card-group__button__text">Dean Academics</span></button>*/}


                                    </div>
                                </div>
                                <div className="card-group__main">
                                    <div className="card-group__item card-group__item--top card-group__item1">
                                        <button className="h6 card-group__button card-group__button--item btn1">Management</button>
                                        <div className="card-group__card page1">
                                            
                                            <a className="card-group__image">
                                                <img width="792" height="792" src="./assets/global/M_1.png" className="attachment-md-lg size-md-lg" alt="management" loading="lazy" sizes="(max-width: 792px) 100vw, 792px" />
                                            </a>
                                            <div className="card-group__content">
                                                <h3 className="h6 card-group__title"><a>Shri. CH. Malla Reddy</a></h3>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-group__item card-group__item2 ">
                                        <button className="h6 card-group__button card-group__button--item btn2">Principal</button>
                                        <div className="card-group__card page2">
                                            <div className="card-group__content">
                                                <h3 className="h6 card-group__title"><a>Dr. Y. Madhavee Latha</a></h3>

                                            </div>
                                            <a className="card-group__image">
                                                <img width="792" height="792" src="./assets/global/m_2.png" className="attachment-md-lg size-md-lg" alt="management" loading="lazy" sizes="(max-width: 792px) 100vw, 792px" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="card-group__item card-group__item3">
                                        <button className="h6 card-group__button card-group__button--item btn3">Chairman</button>
                                        
                                        <div className="card-group__card page3">
                                            <div className="card-group__content">
                                                <h3 className="h6 card-group__title"><a> Dr. CH. Bhadra Reddy</a></h3>

                                            </div>
                                            <a className="card-group__image">
                                                <img width="792" height="792" src="./assets/global/m_5.png" className="attachment-md-lg size-md-lg" alt="management" loading="lazy" sizes="(max-width: 792px) 100vw, 792px" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="card-group__item card-group__item4">
                                        <button className="h6 card-group__button card-group__button--item btn4">Secretay</button>
                                        <div className="card-group__card page4">
                                            <div className="card-group__content">
                                                <h3 className="h6 card-group__title"><a> CH. Mahender Reddy</a></h3>

                                            </div>
                                            <a className="card-group__image">
                                                <img width="792" height="792" src="./assets/global/m_4.png" className="attachment-md-lg size-md-lg" alt="management" loading="lazy" sizes="(max-width: 792px) 100vw, 792px" />
                                            </a>
                                        </div>
                                    </div>
                                    {/*<div className="card-group__item card-group__item5">*/}
                                    {/*    <button className="h6 card-group__button card-group__button--item btn5">Dean Academics</button>*/}
                                    {/*    <div className="card-group__card page5">*/}
                                    {/*        <div className="card-group__content">*/}
                                    {/*            <h3 className="h6 card-group__title"><a> Dr. D. Raghurami Reddy</a></h3>*/}

                                    {/*        </div>*/}
                                    {/*        <a className="card-group__image">*/}
                                    {/*            <img width="792" height="792" src="./assets/global/M_3.png" className="attachment-md-lg size-md-lg" alt="management" loading="lazy" sizes="(max-width: 792px) 100vw, 792px" />*/}
                                    {/*        </a>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                            </div>


                        </div>
                        
                        <div className="container--lg container--home  px-3 px-lg-0 ally-focus-within">
                            <div className="feature fade-in fade-in--active ally-focus-within">
                                <div className="feature__text">
                                    <h4 className='for-admission-text'>
                                        <span>About</span> <br />
                                        Malla Reddy Engineering College
                                        for Women
                                    </h4>
                                    <p align="justify" >
                                        Malla Reddy Engineering College for Women -MRECW (Autonomous Institution, UGC, Govt. of
                                        India), established in 2008, is approved by AICTE, New Delhi and affiliated to JNT
                                        University, Hyderabad.

                                    </p>

                                    <p><a href="/Home/About" className="button">View More</a></p>
                                </div>
                                <div className="feature__image ally-focus-within">
                                    <a href="#" className="ally-focus-within">
                                        <img width="792" height="529" src="./assets/global/college-pic.png" className="attachment-md-lg size-md-lg" alt="aboutImg" loading="lazy" sizes="(max-width: 792px) 100vw, 792px" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="blog-posts">
                            <div className="container--md fade-in fade-in--active">
                                <div className="blog-posts__header d-flex flex-row justify-content-between">
                                    <h2 className="h5 blog-posts__heading ms-4 ms-lg-0 videos-border pb-2" >360 Degree Virtual Tour</h2>

                                </div>
                                <div className="mt-4 my-lg-5">
                                    <div className='row mx-auto'>
                                        <div className='col-4'>
                                            <a href='https://www.mallareddyecw.com/MRECWNEW/MRECWNEW.html' target='_blank'>
                                                <img src='../assets/images/media/campus-vies.png' alt='img' />
                                                <h4 className='for-admission-text text-center'><span>Campus View</span></h4>
                                            </a>

                                        </div>
                                        <div className='col-4'>
                                            <a href='https://www.mallareddyecw.com/MRECWNEW/MRECWNEW.html' target='_blank'>
                                                <img src='../assets/images/media/department-labs.png' alt='img' />
                                                <h4 className='for-admission-text text-center'><span>Department Labs</span></h4>
                                            </a>

                                        </div>
                                        <div className='col-4'>
                                            <a href='https://www.mallareddyecw.com/MRECWNEW/MRECWNEW.html' target='_blank'>
                                                <img src='../assets/images/media/Facilities.png' alt='img' />
                                                <h4 className='for-admission-text text-center'><span>Facilities</span></h4>
                                            </a>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="news-events ">
                            <div className="container container--md news-events__container fade-in fade-in--active">
                                <div className="news-events__group">
                                    <h4 className=" news-events__heading videos-border">
                                        RECENT EVENTS
                                    </h4>
                                    <div className="news-events__list">
                                        <div className="news-events__item">
                                            <div className="news-events__text">
                                                <h6 className="headline d-flex">
                                                    <a className="link-alt" href="../assets/events/19-Oct-2023 Bathukamma.jpg" target='_blank'>MRECW CULTURAL CLUB presents Bathukamma Sambaralu</a>
                                                    <img class='animatedImg' src="../assets/images/media/NewAnimated.gif"
                                                        alt="img" />
                                                </h6>
                                                <small className="text-red">
                                                    19th
                                                    Oct, 2023
                                                </small>
                                            </div>
                                        </div>
                                        <div className="news-events__item">
                                            <div className="news-events__text">
                                                <h3 className="headline d-flex"><a className="link-alt" href="../assets/events/16-Oct-2023 Innovative Product Design.jpg" target='_blank'>
                                                    EDC & IIC Presents "Innovative Product Design"

                                                </a>
                                                    <img class='animatedImg' src="../assets/images/media/NewAnimated.gif"
                                                        alt="img" />
                                                </h3>
                                                <small className="text-red">
                                                    16th
                                                    Oct, 20233
                                                </small>
                                            </div>
                                        </div>
                                        <div className="news-events__item">
                                            <div className="news-events__text">
                                                <h3 className="headline d-flex"><a className="link-alt" target='_blank' href="../assets/events/11-Oct-2023 Movie Song Launch.jpg">

                                                    Aadhikesava movie song launch  
                                                </a>
                                                    <img class='animatedImg' src="../assets/images/media/NewAnimated.gif"
                                                        alt="img" />
                                                </h3>
                                                <small className="text-red">
                                                    10th - 13th Oct, 2023
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <a href="Home/Events" className="button button--inline">View All Events</a>
                                </div>
                                <div className="news-events__group">
                                    <h4 className="news-events__heading videos-border">Previous Events</h4>
                                    <div className="news-events__list">
                                        <div className="news-events__item">
                                            <div className="news-events__text">
                                                <h3 className="headline"><a className="link-alt" target='_blank' href="../assets/events/10-Oct-2023 Cambridge Cisco.jpg">
                                                    Graduation Ceremony Cambridge English Empower Level C1 & Cisco Certifications for 2022-26 B.Tech Students

                                                </a></h3>
                                                <small className="text-red">
                                                    10th
                                                    Oct, 2023
                                                </small>
                                            </div>
                                        </div>
                                        <div className="news-events__item">
                                            <div className="news-events__text">
                                                <h3 className="headline"><a className="link-alt" target='_blank' href="../assets/events/09-Oct-2023_orientation day-2023 - PG.pdf">
                                                    Orientation Programme - 2023 (PG Programmmes - MBA & M.Tech)

                                                </a></h3>
                                                <small className="text-red">
                                                    09th
                                                    Oct, 2023
                                                </small>
                                            </div>
                                        </div>
                                        <div className="news-events__item">
                                            <div className="news-events__text">
                                                <h3 className="headline"><a className="link-alt" target='_blank' href="../assets/events/07-Oct-2023 Musical night.jpg">
                                                    MRECW CULTURAL CLUB presents Joyful JAMBOREE 2K23

                                                </a></h3>
                                                <small className="text-red">
                                                    07th
                                                    Oct, 2023
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <a href="Home/Events" className="button button--inline">View All Events</a>
                                </div>
                            </div>
                        </div>
                        <div className="blog-posts">
                            <div className="container--md fade-in fade-in--active">
                                <div className=" d-flex flex-row justify-content-between ">
                                    <h2 className="h5 blog-posts__heading ms-4 ms-lg-0 videos-border pb-2" >Our videos</h2>
                                    <a href="https://www.youtube.com/@MRECWonline/featured" target='_blank' className="button button--inline d-none d-lg-block">View all</a>
                                </div>
                                <div className="mt-4 mb-0 my-lg-5">
                                    <div id="carouselExampleIndicators3" className="carousel slide">
                                        <div className="carousel-indicators">
                                            <button type="button" data-bs-target="#carouselExampleIndicators3" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                            <button type="button" data-bs-target="#carouselExampleIndicators3" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                        </div>
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="blog-posts__item">
                                                            <div className="blog-posts__image">
                                                                <iframe width="100%" height="315" src="https://www.youtube.com/embed/ql6qcM8xOtE?si=iX_k4I23A7ZwmALs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                                            </div>
                                                            <div className="blog-posts__content">

                                                                <h3 className="headline"><a href="#" className="link-alt">MRECW Success meet </a></h3>
                                                                <div className="blog-posts__meta small text-red">
                                                                    <small>March 25th, 2023</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 d-none d-md-block">
                                                        <div className="blog-posts__item">
                                                            <div className="blog-posts__image">
                                                                <iframe width="100%" height="315" src="https://www.youtube.com/embed/_K7GoQmzBWg?si=MhpYEEC393MgOkCO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                                            </div>
                                                            <div className="blog-posts__content">

                                                                <h3 className="headline"><a href="#" className="link-alt">MRECW AAHLAD 2K23 </a></h3>
                                                                <div className="blog-posts__meta small text-red">
                                                                    <small>April 04th, 2023</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="carousel-item">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="blog-posts__item">
                                                            <div className="blog-posts__image">
                                                                <iframe width="100%" height="315" src="https://www.youtube.com/embed/qbQUh82AuMQ?si=S6aRxKHHVhp61Gy8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                                            </div>
                                                            <div className="blog-posts__content">

                                                                <h3 className="headline"><a href="#" className="link-alt">MRECW Graduation Day
                                                                    Celebrations(2018-22 Batch) </a></h3>
                                                                <div className="blog-posts__meta small text-red">
                                                                    <small>May 28th, 2022</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 d-none d-md-block">
                                                        <div className="blog-posts__item">
                                                            <div className="blog-posts__image">
                                                                <iframe width="100%" height="315" src="https://www.youtube.com/embed/6HdpmeGdJUo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen=""></iframe>
                                                            </div>
                                                            <div className="blog-posts__content">

                                                                <h3 className="headline"><a href="#" className="link-alt">MRECW Graduation Day
                                                                    Celebrations(2018-22 Batch) </a></h3>
                                                                <div className="blog-posts__meta small text-red">
                                                                    <small>May 28th, 2022</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators3" data-bs-slide="prev">
                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span className="visually-hidden">Previous</span>
                                        </button>
                                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators3" data-bs-slide="next">
                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span className="visually-hidden">Next</span>
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                       
                        <div className="my-4 my-lg-5">
                            <div id="carouselExampleIndicators2" className="carousel slide">
                                <div className="carousel-indicators">
                                    <button type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                </div>
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img src="./assets/banner/banner-down-1-.png" className="d-block w-100" alt="..." />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="./assets/banner/banner-down-2-.png" className="d-block w-100" alt="..." />
                                    </div>

                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>


                    </div>
                    <div className="col-lg-3 order-3 order-lg-3">
                        <div className="sideBtnBar mx-4 mx-lg-0 me-lg-5 pt-0">
                            <aside className="Homesidebar mx-3 mx-lg-0">
                                <nav className="sideNav">
                                    <a href="/Home/ExamBranchAutonomous"> <img className="img-fluid py-1 mx-auto" src="./assets/navbarImages/right-1.png" alt="" />

                                        <a href="/Home/Nirf"> <img className="img-fluid py-1 mx-auto" src="./assets/navbarImages/right-2.png" alt="" />
                                        </a>

                                        <a href="/Home/Ariia"> <img className="img-fluid mx-auto" src="./assets/navbarImages/right-3.png" alt="" />
                                        </a>
                                        <a href="/Home/SwayamNptel"> <img className="img-fluid py-1 mx-auto" src="./assets/navbarImages/right-4.png" alt="" />
                                        </a>
                                        <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSd8pl0ySxXsxq4OPvmnZ-Lwf2Su03o27QDUMB9TZANhmJclqQ/viewform"> <img className="img-fluid mx-auto" src="./assets/navbarImages/right-5.png" alt="" />
                                        </a>
                                    </a>
                                    <a href="/Home/InfrastructureFacilities"> <img className="img-fluid pt-1 mx-auto" src="./assets/navbarImages/right-6.png" alt="" />
                                    </a>
                                    <a ref="https://ik.imagekit.io/ckjdgz4vmo/transport.docx?updatedAt=1681218998059"> <img className="img-fluid pt-1 mx-auto" src="./assets/navbarImages/right-7.png" alt="" />
                                    </a>
                                    <ul className="pt-1 secondNavbar">
                                        <li><a href="/Home/InnovativePractices"><i className="fa-solid fa-caret-right"></i> Innovative Practices</a></li>
                                        <li><a href="/Home/Standardevents"><i className="fa-solid fa-caret-right"></i> Standard Events</a></li>
                                        <li><a href="https://ik.imagekit.io/ckjdgz4vmo/NBAnew.pdf?updatedAt=1681191908225" target="_blank"><i className="fa-solid fa-caret-right"></i> NBA Certificate</a></li>
                                        <li><a href="https://ik.imagekit.io/ckjdgz4vmo/NAACnew.jpg?updatedAt=1681191909137" target="_blank"><i className="fa-solid fa-caret-right"></i> NAAC Certificate</a></li>
                                        <li><a href="https://ik.imagekit.io/ckjdgz4vmo/iso-certificate-mrecwnew.jpg?updatedAt=1681191910762" target="_blank"><i className="fa-solid fa-caret-right"></i> ISO Certificate</a></li>
                                        <li><a href="https://ik.imagekit.io/ckjdgz4vmo/EOA_Report.pdf?updatedAt=1681191912326" target="_blank"> <i className="fa-solid fa-caret-right"></i> AICTE LOI &amp; EOA</a></li>
                                        <li><a href="/Home/Photogallery"> <i className="fa-solid fa-caret-right"></i> Photo Gallery</a></li>
                                        <li><a href="/Home/Committees"><i className="fa-solid fa-caret-right"></i> Committees</a></li>
                                        <li><a href="/Home/Media"><i className="fa-solid fa-caret-right"></i> Media - Ads</a></li>

                                        <li><a href="/Home/Newsletter"><i className="fa-solid fa-caret-right"></i> Newsletters</a></li>
                                        <li><a href="https://ik.imagekit.io/ckjdgz4vmo/audit_statements.pdf?updatedAt=1681191912256" target="_blank"><i className="fa-solid fa-caret-right"></i> Audited Statements</a></li>
                                        <li class=''><a href="/Home/Privacypolicy"><i className="fa-solid fa-caret-right"></i> Privacy Policy</a></li>
                                        <a target='_blank' className="mt-1" href="https://www.youtube.com/watch?v=R-zEaQ_G204">
                                            <img className="img-fluid mx-auto mt-1" src="./assets/navbarImages/social-media/youtbe.png" alt="blog" loading="lazy" />
                                        </a>
                                        <a target='_blank' className="mt-2" href="https://www.instagram.com/mrecwautonomous/">
                                            <img className="img-fluid mx-auto mt-1" src="./assets/navbarImages/social-media/instagram.png" alt="blog" loading="lazy" />
                                        </a>
                                        <a target='_blank' className="mt-2" href="https://www.facebook.com/MRECWonline?ref=embed_page">
                                            <img className="img-fluid mx-auto mt-1" src="./assets/navbarImages/social-media/facebook.png" alt="blog" loading="lazy" />
                                        </a>
                                        <a target='_blank' className="mt-1" href="https://www.facebook.com/photo.php?fbid=762011672604853&set=a.435904168548940&type=3&ref=embed_page">
                                            <img className="img-fluid mx-auto mt-1" src="./assets/navbarImages/right-13.jpg" alt="blog" loading="lazy" />
                                        </a>
                                    </ul>
                                    {/*<a className="" href="#">*/}
                                    {/*    <img className="img-fluid mx-auto mt-0 pt-0 blogFirstImg" src="./assets/navbarImages/right-8.png" alt="blog" loading="lazy" />*/}
                                    {/*</a>*/}
                                    {/*<a className="mt-2" href="#">*/}
                                    {/*    <img className="img-fluid mx-auto mt-1" src="./assets/navbarImages/right-9.png" alt="blog" loading="lazy" />*/}
                                    {/*</a>*/}
                                    {/*<a className="mt-2" href="#">*/}
                                    {/*    <img className="img-fluid mx-auto mt-1" src="./assets/navbarImages/right-10.png" alt="blog" loading="lazy" />*/}
                                    {/*</a>*/}
                                    {/*<a className="mt-2" href="#">*/}
                                    {/*    <img className="img-fluid mx-auto mt-1" src="./assets/navbarImages/right-11.png" alt="blog" loading="lazy" />*/}
                                    {/*</a>*/}
                                    {/*<a className="mt-2" href="#">*/}
                                    {/*    <img className="img-fluid mx-auto mt-1" src="./assets/navbarImages/right-12.png" alt="blog" loading="lazy" />*/}
                                    {/*</a>*/}


                                </nav>


                            </aside>


                        </div>
                    </div>
                </div>
                <footer className="footer">
                    <div className="footer__top container--lg mx-auto ">
                        <div className="container footer__top__container">
                            <div className="footer__brand">
                                <div className="footerLogoText">
                                    <a href="#" className="footerMalareddy-logo">
                                        <img className='footer-logo' width="400" src="/assets/malla-reddy-college-logo-footer.png" alt="logo image" loading="lazy" />
                                    </a>

                                    {/*<div className="ms-3 pt-2">*/}
                                    {/*    <h2 className="text-gray d-inline h6 fw-bolder text-uppercase">Malla Reddy Engineering<br />*/}
                                    {/*        College for Women</h2>*/}

                                    {/*    <small className="text-warning d-block font-small">Permanently Affiliated to JNTUH -*/}
                                    {/*        Accredited by NBA &amp; NAAC with A-GRADE</small>*/}
                                    {/*    <small className="text-gray d-block font-small">(Approved by AICTE, New Delhi - ISO*/}
                                    {/*        9001:2015 Certified Institution)</small>*/}

                                    {/*</div>*/}

                                </div>
                                <div>
                                    <p className="pt-2">Malla Reddy Engineering College for Women -MRECW (Autonomous Institution,
                                        UGC, Govt. of India), established in 2008, is approved by AICTE, New Delhi and
                                        affiliated to JNT University, Hyderabad.</p>
                                    <div className="d-flex flex-row justify-content-center">
                                        <a href="../assets/images/media/UGC Qualification.pdf" target="_blank">
                                            <img width="100" src="./assets/global/anti-ragging.png" alt="noRagging" />
                                        </a>
                                    </div>
                                </div>


                            </div>
                            <div className="footer__links">
                                <h6>Location</h6>
                                <div class='py-1'>
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3803.897334329666!2d78.44954800000001!3d17.560074000000014!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb8fa17f6df879%3A0x3160eb49f90a1a75!2sMALLA+REDDY+ENGINEERING+COLLEGE+FOR+WOMEN+(ICET+CODE%3AMRCW)!5e0!3m2!1sen!2sin!4v1436247281311" height='100'></iframe>
                                </div>
                                <p class='footer-text mb-2'>

                                    Maisammaguda, Dhulapally, Post via Kompally,
                                    Secunderabad-500014, Telangana, INDIA. <br />


                                </p>
                                <div className="d-flex footer-text mb-2">
                                    <div className="me-1">
                                        Phone No :
                                    </div>
                                    <div className="d-flex flex-column ">
                                        <div>
                                            +91 7207231199
                                        </div>
                                        <div>
                                            +91 7207031994
                                        </div>
                                    </div>
                                </div>

                                {/*<h4 class='mt-0 mb-2 for-admission-text'><span style={{ fontSize: "10" }}>*/}
                                {/*    For Admissions Call : </span> 09346118803 </h4>*/}
                                <h4 className="mt-0 mb-2 py-2 for-admission-text"><span>For Admissions Call: </span>93461&nbsp;18803</h4>
                                <div className="pt-1 socialmediaIcons d-flex">

                                    <a href="https://www.facebook.com/MRECWonline" target="_blank">
                                        <i className="fa-brands fa-facebook facebookIcon"></i>
                                    </a>
                                    <a href="https://www.youtube.com/MRECWonline" target="_blank">
                                        <i className="fa-brands fa-youtube youtubeIcon"></i>
                                    </a>
                                    <a href="https://www.instagram.com/mrecwautonomous/" target="_blank">
                                        <i className="fa-brands fa-instagram instagramIcon"></i>
                                    </a>
                                    <a href="https://twitter.com/MrecwA/" target="_blank">
                                        <i className="fa-brands fa-twitter twitterIcon" target=""></i>
                                    </a>

                                </div>
                            </div>
                            <div class="footer__related footer__150__wrapper">
                                <div class="footer__150 ">

                                </div>
                                <div class="footer__150__content">
                                    <img class="" src="/assets/certified/100 percent 2022-23.jpeg" alt="College Brochure" loading="lazy" />
                                    <a class="button button--inline" href="https://ik.imagekit.io/ckjdgz4vmo/MRECW_Brochure__1_.pdf?updatedAt=1684140964430" target="_blank" rel="noopener">College Brochure Download</a>

                                </div>
                            </div>
                            <button id='back-to-top' className="footer__back" data-scroll-target=".canvas"><span className="hide">Back to
                                top</span></button>
                        </div>
                    </div>

                    <div className="footer__bottom">
                        <div className="container footer__bottom__container container--lg">
                            <div className="social footer__social">
                                <p className="footer__copyright">&copy; Copyright 2008 - 2023. All Rights Reserved by MRECW.

                                </p>
                            </div>
                            <div className="footer__meta">
                                <div className="links-footer template align-items-center">

                                    <p className="pt-4">
                                        <a href="https://zoninnovative.com/" target="_blank" className="text-blue d-flex">
                                            Developed by &nbsp;
                                            <img width="20" src="https://zoninnovative.com/conference/images/logo/zon-logo.png" alt="zon logo" /> &nbsp; Zon
                                            Innovative
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                <div className="text-block text-center ally-focus-within">
                    <a href="#" className="ally-focus-within">
                        <p>EAMCET/ICET CODE: <b>MRCW</b></p>
                    </a>
                </div>
                

                {/*<div class="mlctr-popup" id="EnquiryForm">*/}
                {/*    <div class="mlctr-popup-stripe" id="MlctrPopup">*/}
                {/*        <div class="mlctr-popup-content">*/}
                {/*            <div class="mlctr-form-box">*/}
                {/*                <form onsubmit="return mailocator.action.do('subscribe')" id="MailocatorForm">*/}
                {/*                    <div class="mlctr-message-success" >*/}
                {/*                        <div class="mlctr-content">*/}
                {/*                            <h2>Enquiry for Admissions*/}
                {/*                            </h2>*/}
                {/*                            <a href='http://mrecw.3pixelsonline.in/enquiry.html' target='_blank'>*/}
                {/*                                <input type="button" value="Click Here to Submit Your Details" />*/}
                {/*                            </a>*/}
                {/*                            <div class="mlctr-privacy"></div>*/}
                {/*                        </div>*/}

                {/*                    </div>*/}

                {/*                </form>*/}
                {/*                <a href="#EnquiryForm" class="mlctr-close"><img class="mlctr-close" src="https://www.mlcdn.eu/data/orsay/close.png" /></a>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}


            </div>

        );
    }
}
