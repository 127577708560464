import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    return (
      <header>
        {/*<Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>*/}
        {/*  <NavbarBrand tag={Link} to="/">Mallareddy_React_Project</NavbarBrand>*/}
        {/*  <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />*/}
        {/*  <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>*/}
        {/*    <ul className="navbar-nav flex-grow">*/}
        {/*      <NavItem>*/}
        {/*        <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>*/}
        {/*      </NavItem>*/}
        {/*      <NavItem>*/}
        {/*        <NavLink tag={Link} className="text-dark" to="/counter">Counter</NavLink>*/}
        {/*      </NavItem>*/}
        {/*      <NavItem>*/}
        {/*        <NavLink tag={Link} className="text-dark" to="/fetch-data">Fetch data</NavLink>*/}
        {/*      </NavItem>*/}
        {/*    </ul>*/}
        {/*  </Collapse>*/}
            {/*</Navbar>*/}

            <body class="home page-template page-template-template-home page-template-template-home-php page page-id-30924 body--header-overlay tribe-no-js">
                <ul id="be_sdkms_capsule" style="display: none !important;">
                    <li id="be_sdkms_sdk_version">be_ixf; php_sdk; php_sdk_1.4.9</li>
                    <li id="be_sdkms_capsule_connect_timer">16 ms</li>
                    <li id="be_sdkms_capsule_index_time">iy_2023; im_02; id_15; ih_22; imh_23; i_epoch:1676528605237</li>
                    <li id="be_sdkms_capsule_pub">ixf-compiler; ixf-compiler_1.0.0.0</li>
                    <li id="be_sdkms_capsule_date_modified">py_2023; pm_02; pd_08; ph_01; pmh_45; p_epoch:1675849542109</li>
                </ul>
                <ul id="be_sdkms_node" style="display: none !important;">
                    <li id="be_sdkms_pub">link-block; link-block_link-block; bodystr</li>
                    <li id="be_sdkms_date_modified">pn_tstr:Wed Feb 08 01:45:42 PST 2023; pn_epoch:1675849542109</li>
                    <li id="be_sdkms_timer">0 ms</li>
                </ul>
                <ul id="be_sdkms_capsule" style="display: none !important;"></ul>
                <noscript>
                    <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WR664SW" height="0" width="0"
                        style="display: none; visibility: hidden;"></iframe>
                </noscript>
                <div class="canvas">
                    <header class="header">
                        <div class="header__wrap">
                            <div class="container header__container pb-4 px-lg-5">
                                <div class="d-flex">
                                    <a href="index.html" class="malareddy-logo d-none d-lg-block">
                                        <img width="400" src="/assets/LOGO-MALLA-REDDY-.png" alt="logo image" loading="lazy" />
                                    </a>
                                    <a href="#" class="malareddy-logo d-block d-lg-none">
                                        <img class="img-fluid" src="/assets/white-logo-mallreddy-w.png" alt="logo image" loading="lazy" />
                                    </a>
                                   
                                </div>
                              
                                <div class="header__search">
                                    <form id="search-form" class="header__form" method="get"
                                        action="#">
                                        <label class="header__label">
                                            <span class="hide">Search</span>
                                            <input type="search" class="header__input" placeholder="Type here to search"
                                                id="cludoquery" name="cludoquery" />
                                        </label>
                                        <button type="submit" class="header__submit">Go</button>
                                    </form>
                                    
                                </div>
                                <button class="header__menu-toggle"><span class="hide">Menu</span></button>
                                <div class="header__menu">
                                    <div class="header__menu__wrap">
                                        <img class="img-fluid pb-lg-5" src="/assets/logo/new-top-icons.png" alt="icon" loading="lazy" />
                                        <nav class="header__primary">
                                            <ul id="menu-navigation" class="header__primary__list">
                                                <li id="menu-item-639"
                                                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-639">
                                                    <a href="#">About College</a>
                                                </li>
                                                <li id="menu-item-640"
                                                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-640">
                                                    <a target="_blank" href="#">Infrastructure</a>
                                                </li>
                                                <li id="menu-item-640"
                                                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-640">
                                                    <a target="_blank" href="#">Admissions</a>
                                                </li>
                                                <li id="menu-item-640"
                                                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-640">
                                                    <a target="_blank" href="#">Departments</a>
                                                </li>
                                                <li id="menu-item-640"
                                                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-640">
                                                    <a target="_blank" href="#">Placements</a>
                                                </li>
                                                <li id="menu-item-640"
                                                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-640">
                                                    <a target="_blank" href="#">Faculty</a>
                                                </li>
                                                <li id="menu-item-640"
                                                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-640">
                                                    <a target="_blank" href="#">Courses</a>
                                                </li>
                                                <li id="menu-item-640"
                                                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-640">
                                                    <a target="_blank" href="#">Events</a>
                                                </li>

                                                <li id="menu-item-640"
                                                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-640">
                                                    <a target="_blank" href="#">Careers</a>
                                                </li>
                                                <li id="menu-item-640"
                                                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-640">
                                                    <a target="_blank" href="#">Alumni</a>
                                                </li>
                                                <li id="menu-item-640"
                                                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-640">
                                                    <a target="_blank" href="#">Contact</a>
                                                </li>
                                                <li id="menu-item-640"
                                                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-640">
                                                    <a target="_blank" href="#">
                                                        <b>
                                                            <i class="fa-solid fa-right-to-bracket"></i>
                                                            Login
                                                        </b>
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 d-flex align-items-cente marqueeeLine" style="width:105%">
                                <p class="news-heading">UPDATES</p>
                                <marquee style="background-image: linear-gradient(0deg,#1d3243 0%,#3c6789 100%);"
                                    class="col-md-11 y p-1" behavior="scroll" direction="left" scrollamount="5"
                                    onmouseover="this.stop();" onmouseout="this.start();">
                                    <a href="#" class="text-white">
                                        Proud Moments forMRECW !!
                                        Hearty Congratulations - 387 Students placed in ACCENTURE from 2019 - 23 Batch.
                                    </a>
                                    <a href="#" class="text-white">
                                        368 Students placed in DXC TECHNOLOGY from 2019 - 23 Batch.
                                    </a>
                                    <a href="#" class="text-white">
                                        339 Students placed in HCL TECHNOLOGY from 2019 - 23 Batch.
                                    </a>
                                    <a href="#" class="text-white">
                                        198 Students placed in COGNIZANT from 2019 - 23 Batch.
                                    </a>
                                    <a href="#" class="text-white">
                                        2 Students placed in Lowes India with highest package of 19.5 LPA from 2019 - 23 Batch.
                                        vement ofMRECW !! NIRF Indian Ranking - 2021, Accepted by MHRD, Govt. of India:: -vement of
                                       MRECW !! NIRF Indian Ranking - 2021, Accepted by MHRD, Govt. of India:: -
                                    </a>
                                    <a href="#" class="text-white">
                                        ::Congatulations toMRECW for more than 65,000 online student
                                        certifications completion during COVID-19 period till date, in trending technologies through
                                        Coursera like AI &amp; ML, IOT, Cloud Computing, Cyber Security, Data Science, etc, CISCO C,
                                        CISCO Python, CISCO Linux, Pearson English certification, TCS ION certifications and IBM
                                        Cognitive courses (AI &amp; ML, Data Science &amp; Python) and Amazon wow.::
                                    </a>
                                    <a href="#" class="text-white">
                                        ::Congatulations to 1st year B.Tech students for completing more
                                        than 20,000 online certifications during COVID-19 period till date. :: -
                                    </a>
                                    <a href="#" class="text-white">
                                        ::Congatulations to CSE students for completing more than 18,000
                                        online certifications during COVID-19 period till date:: -
                                    </a>
                                    <a href="#" class="text-white">
                                        ::Congatulations to ECE students for completing more than 17,000
                                        online certifications during COVID-19 period till date:: -
                                    </a>
                                    <a href="#" class="text-white">
                                        :: Congatulations to ECE students for completing more than 17,000
                                        online certifications during COVID-19 period till date.:: -
                                    </a>
                                    <a href="#" class="text-white">
                                        :: Congatulations to IT students for completing more than 4,500
                                        online certifications during COVID-19 period till date.:: -
                                    </a>
                                    <a href="#" class="text-white">
                                        :: Congatulations to EEE students for completing more than 5,500
                                        online certifications during COVID-19 period till date.:: -
                                    </a>
                                </marquee>
                            </div>
                        </div>
                    </header>

              
                    <div id="scrollto" class="scrollto"></div>
                    <div class="omnilert">
                        <div class="omnilert__container container">
                            <script type="text/javascript"
                                src="http://widgets.omnilert.net/5fb267efe64a62be1d534df4ecebcee8-621"></script>
                            <button class="omnilert__close">Close</button>
                        </div>
                    </div>
                </div>

            </body>

      </header>
    );
  }
}
